export default {

  name: 'Group',

  group: {
    id: '',
    key: '',
    name: '',
    description: '',
    deletable: true,
    numberOfChildren: 0,
    facilityReference: '',
    facilityReferenceNumber: '',
    relevantForLandesamtStatistik: true,
  }
}
<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" id="selectChildDialogId" v-if="group" :md-active.sync="showDialog"
               @md-closed="closeDialog">
      <md-dialog-title>
        <div class="grid-x grid-padding-x">
          <div class="cell large-9 medium-9">
            <i class="fi-plus"></i><img width="25px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Neue Kinder
            der Gruppe <span class="group-title">{{group.name}}</span> zuweisen
          </div>
          <div class="cell large-2 medium-2" style="margin-top: -5px;">
            <SessionTime></SessionTime>
          </div>
          <div class="cell large-1 medium-1 hide-for-small-only"></div>
        </div>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <md-table id="selectChildTableId"
                  v-model="searchedOrganizationChildren" md-sort="masterdata.lastname" md-sort-order="asc" md-card md-fixed-header
                  @md-selected="onSelectChildren">
          <md-table-toolbar>

            <div class="page-container md-layout-column">

              <md-drawer class="md-right" :md-active.sync="moreFilterVisible" style="width: 70%" md-swipeable>

                <div class="md-layout">
                  <div class="md-layout-item md-size-10 text-center" style="padding: 7px;">
                    <md-button class="md-icon-button md-raised md-primary" @click="resetChildFilter">
                      <md-icon>undo</md-icon>
                      <md-tooltip md-direction="bottom" style="font-size: medium;">Alle Filtereinstellungen zurücksetzen
                      </md-tooltip>
                    </md-button>
                  </div>
                  <div class="md-layout-item md-size-90">
                    <md-tabs v-if="childFilter" md-elevation="1" style="height: 100%;" @md-changed="closeChildFilter">
                      <md-tab id="Allgemein" md-label="Allgemein">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.handicapped">Behinderung</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.migrantBackground">Migrationshintergrund</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.nutritionInfo.porkMeatAllowed">Darf Schweinefleisch essen</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.possiblePreschooler">Mögliches Vorschulkind</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.preschooler">Vorschulkind</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.shareInformationAllowed">Datenweitergabe-Erlaubnis</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.sibling">Geschwister</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.gettingWirtschaftlicheJugendhilfe">Wirtschaftliche Jugendhilfe</md-checkbox><br>
                      </md-tab>
                      <md-tab id="Gesundheit" md-label="Gesundheit">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.nutritionInfo.allergic">Allergien</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.firstMeaslesVaccinationDate">Keine erste Masernimpfung</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.secondMeaslesVaccinationDate">Keine zeite Masernimpfung</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.exemptionMeaslesVaccination">Freistellung Masernimpfung</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.exemptionSecondMeaslesVaccination">Freistellung 2. Masernimpfung</md-checkbox><br>
                      </md-tab>
                      <md-tab id="Zustimmungen" md-label="Zustimmungen">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-accent" v-model="childFilter.parentalConsents.dsgvoConfirmationObtained">Einwilligung Datenverarbeitung <b style="color: seagreen;">vollständig</b></md-checkbox>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.applyPlasterSalveAllowed">Pflaster applizieren / Salbengabe</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.beingPhotographedPrivatelyAllowed">Fotonutzung für interne Zwecke erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.beingPhotographedPubliclyAllowed">Fotonutzung für öffentliche Zwecke erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.contactToAnimalsAllowed">Kontakt mit Tieren erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.goSwimmingAllowed">Schwimmbadbesuch inkl. Schwimmen erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.removeTickAllowed">Zeckenentfernung erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.ridingCarAllowed">Beförderung in Kraftfahrzeugen erlaubt</md-checkbox><br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary" v-model="childFilter.parentalConsents.applySuncreamAllowed">Sonnencreme anwenden erlaubt</md-checkbox><br>
                      </md-tab>
                      <md-tab id="Individuell" md-label="Individuell">
                        <div v-if="selectedFacility && selectedFacility.facilitySettings && selectedFacility.facilitySettings.freeAttributes">
                          <div v-for="attribute of selectedFacility.facilitySettings.freeAttributes" :key="attribute.refNr">
                            <div v-if="attribute.type === 'BOOLEAN'">
                              <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                           v-model="attribute.booleanValue">{{ attribute.name }}
                              </md-checkbox>
                              <br>
                            </div>
                          </div>
                          <div v-if="facilityHasNoFreeAttributesCheckboxes(selectedFacility)">
                            <h6>
                              Für die Kinder der Einrichtung <b style="color: coral">{{ selectedFacility.name }}</b>
                              sind keine individuellen Merkmale vom Typ <i>Checkbox</i> angelegt.<br><br>
                              Im Bereich <a @click="onSaveAndExitTo('/Verwaltung/Anpassungen')">Verwaltung / Anpassungen</a>
                              können individuelle Merkmale festgelegt werden.
                            </h6>
                          </div>
                        </div>
                        <div v-else>
                          <h5><i>Bitte wählen Sie eine Einrichtung aus, um die individuellen Merkmale nutzen zu können.</i></h5>
                        </div>
                      </md-tab>
                      <md-tab id="close" md-label="" md-icon="cancel">
                      </md-tab>
                    </md-tabs>
                  </div>
                </div>

              </md-drawer>

              <md-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-5 filter-menu" @click="moreFilterVisible = true">
                    <div class="filter-label" >mehr FILTER <span v-if="childFilterActiveCounter > 0" class="flash-orange-red"><b><i>aktiv</i></b></span></div>
                    <md-tooltip md-direction="right" style="font-size: medium;">
                      Klicken für weitere Filter
                    </md-tooltip>
                  </div>
                  <div class="md-layout-item">
                    <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                      <div class="cell large-6 hide-for-medium-only hide-for-small-only">
                        <p class="md-title ai-number">{{searchedOrganizationChildren.length}} Kinder verfügbar</p>
                      </div>
                      <div class="cell large-6 medium-12 small-12">
                        <md-field md-clearable class="md-toolbar-section-end">
                          <md-input placeholder="Suche Nachname ..." v-model="searchChild"
                                    @input="searchOnChildTable"/>
                        </md-field>
                      </div>
                      <div class="cell large-6 medium-6 small-6">
                        <md-field>
                          <label>Filter</label>
                          <md-select v-model="selectedFilter" :disabled="sending" @md-selected="onSelectFilter" multiple>
                            <md-option v-for="filter of filters" :value="filter.id" :key="filter.id">
                              {{filter.name}}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="cell large-6 medium-6 small-6">
                        <md-field>
                          <label>Bereiche</label>
                          <md-select v-model="selectedDivisions" :disabled="sending" @md-selected="onSelectDivision" multiple>
                            <md-option v-for="division of labels.divisionTypes" :value="division" :key="division">
                              {{labels.divisiontype[division]}}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </div>
              </md-content>

            </div>

          </md-table-toolbar>

          <md-table-empty-state v-if="searchChild" id="tableEmptyStateId"
                                md-label="Kein Kind gefunden"
                                :md-description="`Kein Kind mit dem Suchbegriff '${searchChild}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="multiple" md-auto-select>
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Vorname" md-sort-by="masterdata.firstname">
              <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;{{ item.masterdata.firstname }}
            </md-table-cell>
            <md-table-cell md-label="Nachname" md-sort-by="masterdata.lastname">{{ item.masterdata.lastname }}
            </md-table-cell>
            <md-table-cell md-label="Alter" md-sort-by="age">{{ item.age }}
            </md-table-cell>
            <md-table-cell md-label="Bereich" md-sort-by="divisionTypeLabel">{{ item.divisionTypeLabel }}
            </md-table-cell>
          </md-table-row>
        </md-table>

        <br>
        <md-progress-bar v-if="sending && progress > 0" md-mode="determinate" :md-value="progress"/>
        <br>

        <div v-if="!isSage" class="grid-x grid-padding-x">
          <div class="cell large-6 medium-12" style="padding-left: 0">
            <div v-if="sending">
              <vue-simple-spinner></vue-simple-spinner>
            </div>
            <button v-else @click="onAssignChildren" class="button success expanded" :disabled="selectedChildren.length <= 0">
                <span>
                  <img width="15px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;
                  <span v-if="selectedChildren.length > 0">{{selectedChildren.length}}&nbsp;</span>Kind<span v-if="selectedChildren.length > 1">er</span> der Gruppe zuweisen
                </span>
            </button>
          </div>
          <div class="cell large-6 medium-12" style="padding-right: 0">
            <button @click="closeDialog" class="button alert expanded" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
            </button>
          </div>
        </div>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import Vue from 'vue';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import ChildService from '../../services/ChildService';
  import GroupService from '../../services/GroupService';
  import ImageView from '../../components/views/ImageView';
  import Child from '../../entities/Child';
  import SessionTime from '../animations/SessionTime';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchChildByLastname = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
    }

    return items;
  };

  const filterKids = (items, selectedFilter) => {
    return items.filter(item => {
      if (selectedFilter.includes('CURRENT') && selectedFilter.includes('FUTURE')) {
        return true;
      } else if (selectedFilter.includes('CURRENT') && !selectedFilter.includes('FUTURE')) {
        return !Vue.moment().isBefore(item.entryDate);
      } else if (!selectedFilter.includes('CURRENT') && selectedFilter.includes('FUTURE')) {
        return Vue.moment().isBefore(item.entryDate);
      } else {
        return false;
      }
    });
  };

  const searchByFacility = (items, facilityReference) => {
    if (facilityReference) {
      return items.filter(item => {
        return toLower(item.facilityReference).includes(toLower(facilityReference));
      });
    }

    return items;
  };

  const searchByDivision = (items, terms) => {
    if (terms) {
      return items.filter(item => {
        for (let term of terms) {
          if (toLower(item.divisiontype).includes(toLower(term))) {
            return true;
          }
        }
        return false;
      });
    }

    return items;
  };

  const searchByFeature = (items, childFilter) => {
    return items.filter(item => {
      const filterResults = [];
      if (childFilter.handicapped) {filterResults.push(childFilter.handicapped === item.handicapped);}
      if (childFilter.migrantBackground) {filterResults.push(childFilter.migrantBackground === item.migrantBackground);}
      if (childFilter.nutritionInfo.porkMeatAllowed) {filterResults.push(childFilter.nutritionInfo.porkMeatAllowed === item.nutritionInfo.porkMeatAllowed);}
      if (childFilter.possiblePreschooler) {filterResults.push(childFilter.possiblePreschooler === item.possiblePreschooler);}
      if (childFilter.preschooler) {filterResults.push(childFilter.preschooler === item.preschooler);}
      if (childFilter.shareInformationAllowed) {filterResults.push(childFilter.shareInformationAllowed === item.shareInformationAllowed);}
      if (childFilter.sibling) {filterResults.push(childFilter.sibling === item.sibling);}
      if (childFilter.gettingWirtschaftlicheJugendhilfe) {filterResults.push(childFilter.gettingWirtschaftlicheJugendhilfe === item.gettingWirtschaftlicheJugendhilfe);}

      if (childFilter.nutritionInfo.allergic) {filterResults.push(childFilter.nutritionInfo.allergic === item.nutritionInfo.allergic);}
      if (childFilter.firstMeaslesVaccinationDate) {return (item.firstMeaslesVaccinationDate === null);}
      if (childFilter.secondMeaslesVaccinationDate) {return (item.secondMeaslesVaccinationDate === null);}
      if (childFilter.exemptionMeaslesVaccination) {filterResults.push(childFilter.exemptionMeaslesVaccination === item.exemptionMeaslesVaccination);}
      if (childFilter.exemptionSecondMeaslesVaccination) {filterResults.push(childFilter.exemptionSecondMeaslesVaccination === item.exemptionSecondMeaslesVaccination);}

      if (childFilter.parentalConsents.dsgvoConfirmationObtained) {
        let result = item.masterdata.dsgvoConfirmationObtained;
        for (let relatedPerson of item.relatedPersons) {
          if (relatedPerson.dsgvoConfirmationObtained === false) {
            result = false;
          }
        }
        return result;
      }
      if (childFilter.parentalConsents.applyPlasterSalveAllowed) {filterResults.push(childFilter.parentalConsents.applyPlasterSalveAllowed === item.parentalConsents.applyPlasterSalveAllowed);}
      if (childFilter.parentalConsents.beingPhotographedPrivatelyAllowed) {filterResults.push(childFilter.parentalConsents.beingPhotographedPrivatelyAllowed === item.parentalConsents.beingPhotographedPrivatelyAllowed);}
      if (childFilter.parentalConsents.beingPhotographedPubliclyAllowed) {filterResults.push(childFilter.parentalConsents.beingPhotographedPubliclyAllowed === item.parentalConsents.beingPhotographedPubliclyAllowed);}
      if (childFilter.parentalConsents.contactToAnimalsAllowed) {filterResults.push(childFilter.parentalConsents.contactToAnimalsAllowed === item.parentalConsents.contactToAnimalsAllowed);}
      if (childFilter.parentalConsents.goSwimmingAllowed) {filterResults.push(childFilter.parentalConsents.goSwimmingAllowed === item.parentalConsents.goSwimmingAllowed);}
      if (childFilter.parentalConsents.removeTickAllowed) {filterResults.push(childFilter.parentalConsents.removeTickAllowed === item.parentalConsents.removeTickAllowed);}
      if (childFilter.parentalConsents.ridingCarAllowed) {filterResults.push(childFilter.parentalConsents.ridingCarAllowed === item.parentalConsents.ridingCarAllowed);}
      if (childFilter.parentalConsents.applySuncreamAllowed) {filterResults.push(childFilter.parentalConsents.applySuncreamAllowed === item.parentalConsents.applySuncreamAllowed);}

      let result = true;
      for (const fs of filterResults) {
        if (!fs) {
          result = false;
        }
      }
      return result;
    });
  };

  const searchByFreeAttributes = (items, freeAttributesFilter) => {
    return items.filter(item => {
      const filterResults = [];
      freeAttributesFilter.forEach(freeAttributeOfFilter => {
        if (freeAttributeOfFilter.type === 'BOOLEAN') {
          if (freeAttributeOfFilter.booleanValue === true) {
            item.freeAttributes.forEach(freeAttributeOfChild => {
              if (freeAttributeOfChild.type === 'BOOLEAN' && freeAttributeOfChild.refNr === freeAttributeOfFilter.refNr) {
                filterResults.push(freeAttributeOfFilter.booleanValue === freeAttributeOfChild.booleanValue)
              }
            })
          }
        }
      })

      let result = true;
      for (const fs of filterResults) {
        if (!fs) {
          result = false;
        }
      }
      return result;
    });
  }

  export default {
    name: 'SelectChildDialog',

    components: {
      ImageView,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    props: {
      group: {
        required: true
      },
      children: {
        required: true
      },
      selectedFacility: {
        required: true
      },
      showDialog: {
        required: true
      },
    },

    watch: {
      showDialog() {
        this.resetStates();
        if (this.showDialog) {
          setTimeout(() => this.resizeDialogElements(), 250);
        }
      },
    },

    mounted() {
      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });
    },

    data: () => ({
      sending: false,
      selectedChildFullName: '',
      searchChild: null,
      selectedChild: null,
      selectedChildren: [],
      organizationChildren: [],
      searchedOrganizationChildren: [],
      responseCount: 0,
      selectedDivisions: [ 'KRIPPE', 'KINDERGARTEN', 'UNDEFINED', 'HORT' ],
      selectedFilter: ['FUTURE', 'CURRENT'],
      filters: [
        {name: 'zukünftige Kinder', id: 'FUTURE'},
        {name: 'aktuelle Kinder', id: 'CURRENT'},
      ],

      childFilter: JSON.parse(JSON.stringify(Child.child)),
      childFilterActiveCounter: 0,
      lastSelectedFilterTabId: 'Allgemein',
      moreFilterVisible: false,

      progress: 0,
    }),

    methods: {

      closeChildFilter(id) {
        if (id === 'close') {
          jQuery('div:contains("' + this.lastSelectedFilterTabId + '")').trigger('click');
          this.moreFilterVisible = false;
        } else {
          this.lastSelectedFilterTabId = id;
        }
      },

      removeGroupChildren(kids) {
        let remainingKids = [];
        for (let kid of kids) {
          let found = false;
          for (let child of this.children) {
            if (kid.id === child.id) {
              found = true;
            }
          }
          if (!found) {
            remainingKids.push(kid);
          }
        }
        return remainingKids;
      },

      getAllOrganizationChildren() {
        this.sending = true;
        ChildService.getAllOrganizationChildren()
          .then(response => {
            this.organizationChildren = this.removeGroupChildren(response.data);
            this.selectedChild = null;
            this.searchChild = null;
            this.searchOnChildTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Daten aller Kinder für Träger ' + this.organization.name);
            this.searchOnChildTable();
            this.sending = false;
          })
      },

      onSelectFilter() {
        this.searchOnChildTable();
      },

      resetChildFilter() {
        this.childFilterActiveCounter = 0;
        this.childFilter = JSON.parse(JSON.stringify(Child.child));
        if (this.selectedFacility && this.selectedFacility.facilitySettings && this.selectedFacility.facilitySettings.freeAttributes) {
          this.selectedFacility.facilitySettings.freeAttributes.forEach(attribute => {
            if (attribute.type === 'BOOLEAN') {
              attribute.booleanValue = false;
            }
          })
        }
        this.searchOnChildTable();
      },

      onSelectDivision() {
        this.searchOnChildTable();
      },

      maintainChildFilterActivityState(state) {
        if (state) {
          this.childFilterActiveCounter++;
        } else {
          this.childFilterActiveCounter--;
        }
        this.searchOnChildTable();
      },

      facilityHasNoFreeAttributesCheckboxes(facility) {
        let result = true;
        facility.facilitySettings.freeAttributes.forEach(attribute => {
          if (attribute.type === 'BOOLEAN') {
            result = false;
          }
        })
        return result;
      },

      searchOnChildTable() {
        for (let child of this.organizationChildren) {
          child.divisionTypeLabel = this.labels.divisiontype[child.divisiontype];
        }
        this.searchedOrganizationChildren = filterKids(this.organizationChildren, this.selectedFilter);

        this.searchedOrganizationChildren = searchByFacility(this.searchedOrganizationChildren, this.group.facilityReference);
        this.searchedOrganizationChildren = searchByDivision(this.searchedOrganizationChildren, this.selectedDivisions);
        this.searchedOrganizationChildren = searchChildByLastname(this.searchedOrganizationChildren, this.searchChild);
        this.searchedOrganizationChildren = searchByFeature(this.searchedOrganizationChildren, this.childFilter);
        if (this.selectedFacility && this.selectedFacility.facilitySettings) {
          this.searchedOrganizationChildren = searchByFreeAttributes(this.searchedOrganizationChildren, this.selectedFacility.facilitySettings.freeAttributes);
        }
        this.resizeDialogElements();
      },

      resizeDialogElements() {
        let height = jQuery('#selectChildDialogId').height() - 250;
        setTimeout(() => jQuery('#selectChildTableId').attr('style', 'height: ' + height + 'px;overflow-x: hidden;'), 10);
        setTimeout(() => jQuery('#selectChildTableId').children('.md-content.md-table-content').attr('style', 'height: 10%'), 20);
        setTimeout(() => jQuery('.md-table-fixed-header-container').last().find('div.md-table-head-label:contains(Vorname)').attr('style', 'padding: 0px 20px 0px 24px;'), 30);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      closeDialog() {
        this.closeChildFilter('close');
        this.$emit("closeSelectChildDialog");
      },

      onSelectChildren(items) {
        if (items) {
          this.selectedChildren = JSON.parse(JSON.stringify(items));
        } else {
          this.selectedChildren = [];
        }
      },

      onAssignChildren() {
        this.responseCount = 0;
        this.progress = 0;
        for (let child of this.selectedChildren) {
          this.selectedChild = child;
          this.selectedChildFullName = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
          this.onAssignChildToGroup(this.selectedChildren.length);
        }
      },

      onAssignChildToGroup(numRequests) {
        if (this.selectedChild) {
          this.sending = true;
          GroupService.assignChildToGroup(this.selectedChild.id, this.group.id)
            .then(response => {
              this.responseCount++;
              this.progress = Number(this.responseCount / numRequests) * 100;
              if (this.responseCount >= numRequests) {
                this.sending = false;
                if (this.selectedChildren.length === 1) {
                  this.$store.commit('successMsg', 'Das Kind <b>&nbsp;' + this.selectedChildFullName +
                    '</b>&nbsp;wurde erfolgreich der Gruppe &nbsp;<b>' +
                    this.group.name + '</b>&nbsp;zugewiesen.');
                } else {
                  this.$store.commit('successMsg', '<b>' + this.selectedChildren.length +
                    '</b>&nbsp;Kinder wurden erfolgreich der Gruppe &nbsp;<b>' +
                    this.group.name + '</b>&nbsp;zugewiesen.');
                }
                this.selectedChild = null;
                this.$emit("childSuccessfullyAssigned", response.data.numberOfChildren);
                this.closeDialog();
              }
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Zuweisen von Kind ' + this.selectedChildFullName +
                ' zur Gruppe ' + this.group.name);
              this.sending = false;
            })
        }
      },

      resetStates() {
        this.selectedChildren = [];
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels;
      },
    },
  };
</script>

<style scoped>

  .filter-label {
    transform: translateY(2.5rem) translateX(-6.05rem) rotate(270deg);
    font-size: 1rem;
  }

  .md-checkbox {
    margin: 0 0 0 16px;
  }

  .md-dialog-container .md-tabs-navigation {
    padding: 0;
  }

  .md-dialog /deep/.md-dialog-container {
    width: 950px;
    min-height: 95%;
    max-height: 95%;
  }

</style>

<style>

  :root {
    --md-theme-default-primary: seagreen;
    --md-theme-default-accent: #ff5252;
    --md-theme-default-theme: light;

    --md-theme-default-primary-on-background: seagreen;
  }

</style>

<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" v-if="group" :md-active.sync="showDialog" @md-closed="closeDialog">
      <md-dialog-title>
        <form novalidate @submit.prevent="validateGroup">
          <div class="grid-x">
            <div v-if="mode === 'update'" class="cell large-6">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Gruppe bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-6">
              <img width="100px" src="../../assets/icons/children-group.png"/>&nbsp;&nbsp;&nbsp;Neue Gruppe für
              <span class="group-title">{{getFacilityName(group.facilityReferenceNumber)}}</span>
            </div>
            <div class="cell large-1 medium-1 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Gruppe hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </form>
      </md-dialog-title>

      <md-dialog-content>
          <GroupFrom ref="groupForm" :group="group"
                     :sending="sending"
                     :availableFacilities="availableFacilities"
                     :mode="mode"/>

          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>

  import GroupFrom from '../forms/GroupForm';

  import FacilityService from '../../services/FacilityService';
  import GroupService from '../../services/GroupService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import SessionTime from '../animations/SessionTime';

  import {validationMixin} from 'vuelidate'

  export default {
    name: 'EditGroupDialog',
    mixins: [validationMixin],

    components: {
      GroupFrom,
      SessionTime,
    },

    props: {
      group: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      },
    },

    created() {
    },

    data: () => ({
      success: true,
      sending: false,
    }),

    methods: {
      updateGroup() {
        this.sending = true;
        this.success = false;
        GroupService.updateGroup(this.group)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateGroupSuccess", response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Gruppe ' + this.group.name);
            this.sending = false;
          })
      },

      addGroup() {
        this.sending = true;
        this.success = false;

        let facilityReferenceNumber = this.group.facilityReferenceNumber;
        if (facilityReferenceNumber === '*') {
          facilityReferenceNumber = 'all';
        }

        FacilityService.addGroupToFacility(facilityReferenceNumber, this.group)
          .then(response => {
            this.form = response.data[0];
            this.success = true;
            this.sending = false;
            this.$emit("updateGroupSuccess", response.data[0]);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen der Gruppe ' + this.group.name);
            this.sending = false;
          })
      },

      validateGroup() {
        this.$refs.groupForm.validateGroup();

        if (!this.$refs.groupForm.invalid) {

          if (this.mode === 'update') {
            this.updateGroup();
          }
          if (this.mode === 'add') {
            this.addGroup();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditGroupDialog");
      },

      getFacilityName(referenceNumber) {
        if (referenceNumber && referenceNumber.length > 0) {
          for (let facility of this.organization.facilities) {
            if (facility.referenceNumber === referenceNumber) {
              return facility.name;
            }
          }
        }
        return '';
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization;
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 1200px;
  }
</style>

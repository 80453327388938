<template>
  <div v-if="group" class="grid-x grid-padding-x">
    <div class="cell large-4 medium-12">
      <md-field :class="getValidationClass('name')">
        <label for="group-name">Name</label>
        <md-input name="group-name" id="group-name" autocomplete="off" v-model="group.name"
                  :disabled="sending"/>
        <span class="md-error" v-if="!$v.group.name.required">Gruppen-Name ist notwendig</span>
        <span class="md-error" v-else-if="!$v.group.name.minlength">Gruppen-Name muss min. 3 Zeichen lang sein</span>
      </md-field>
    </div>
    <div class="cell large-8 medium-12">
      <md-field>
        <label for="group-description">Beschreibung</label>
        <md-input name="group-description" id="group-description" autocomplete="off" v-model="group.description"
                  :disabled="sending"/>
      </md-field>
    </div>

    <div class="cell large-4 medium-6">
      <md-field v-if="availableFacilities && availableFacilities.length > 0 && mode === 'add'"
                :class="getValidationClass('facilityReferenceNumber')">
        <label>Einrichtung</label>
        <md-select v-model="group.facilityReferenceNumber" :disabled="sending || availableFacilities.length <= 1">
          <md-option v-for="(facility, index) of availableFacilities" v-if="index > 0" :value="facility.referenceNumber"
                     :key="facility.id">{{ facility.longName }}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.group.facilityReferenceNumber.required">Einrichtung ist notwendig</span>
      </md-field>
    </div>
    <div class="cell large-8 medium-6">
      <md-checkbox class="md-primary" v-model="group.relevantForLandesamtStatistik" :disabled="sending">
        <span style="font-size: 1rem">Relevant für Landesamt für Statistik</span>
        <md-tooltip>Soll im Daten-Export für das Landesamt für Statistik enthalten sein.</md-tooltip>
      </md-checkbox>
    </div>
  </div>
</template>

<script>

import {validationMixin} from 'vuelidate'
import {
  required,
  minLength,
} from 'vuelidate/lib/validators'

export default {
  name: 'GroupForm',
  mixins: [validationMixin],

  props: {
    group: {
      required: true
    },
    availableFacilities: {
      required: true
    },
    sending: {
      required: true
    },
    mode: {
      required: true,
    },
  },

  data: () => ({
    invalid: true,
  }),

  validations: {
    group: {
      name: {
        required,
        minLength: minLength(3)
      },
      facilityReferenceNumber: {
        required,
      },
    },
  },

  methods: {
    getValidationClass(fieldName) {
      let field = this.$v.group[fieldName];

      if (fieldName === 'facilityReferenceNumber' && this.group.facilityReferenceNumber === '*') {
        this.group.facilityReferenceNumber = null;
        return {
          'md-invalid': field.$dirty
        }
      }

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateGroup() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;

      if (this.group.facilityReferenceNumber === '*') {
        this.group.facilityReferenceNumber = null;
        this.invalid = true;
      }
    },
  },

  computed: {
    organization() {
      return this.$store.getters.organization;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

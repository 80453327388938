import Person from "../entities/Person"
import Address from "../entities/Address"
import Contact from "../entities/Contact"
import Contract from "./Contract";

export default {

  name: 'Child',

  child: {
    id: '',
    age: '',
    ageTextual: '',
    alerts: [{
      state: '',
      type: null,
      text: '',
    }],
    archived: false,
    beitragsersatz: {},
    bezirkFachkraftstunden: null,
    bookingTimes: [],
    bringzeit: '',
    contactMother: {
      businessPhone: null,
      email: null,
      mobilePhone: null,
      phone: null,
    },
    contactFather: {
      businessPhone: null,
      email: null,
      mobilePhone: null,
      phone: null,
    },
    contactMother2: {
      businessPhone: null,
      email: null,
      mobilePhone: null,
      phone: null,
    },
    contactFather2: {
      businessPhone: null,
      email: null,
      mobilePhone: null,
      phone: null,
    },
    countryOfOrigin: '',
    contracts: [],
    contractsExpired: false,
    currentBookingTime: null,
    currentContract: Contract.contract,
    defaultChild: false,
    divisiontype: 'UNDEFINED',
    emergencyName: null,
    emergencyPersonIds: [],
    emergencyPhonenumber: '',
    entryDate: '',
    exitDate: '',
    exemptionMeaslesVaccination: false,
    exemptionSecondMeaslesVaccination: false,
    facilityReference: '',
    familyLanguage: '',
    father: Person.person,
    fees: [],
    filterState: ['CURRENT'],
    firstMeaslesVaccinationDate: null,
    freeAttributes: [],
    handicapTypes: [],
    handicapped: false,
    healthInformation: {
      insuranceHolderPersonId: '',
      insuranceName: '',
      insuranceNumber: '',
      insuranceContact: Contact.contact,
      pediatrician: Person.person,
      privateInsurance: false,
    },
    hereToday: false,
    gehzeit: '',
    gettingWirtschaftlicheJugendhilfe: false,
    groupReferences: [],
    secondMeaslesVaccinationDate: null,
    imageId: null,
    kibigName: '',
    kommzeit: '',
    kibigId: '',
    masterdata: {
      address: Address.address,
      birthdate: null,
      clubMember: false, // ToDo
      childRelation: 'SELF',
      contact: Contact.contact,
      dsgvoConfirmationObtained: false,
      firstname: '',
      fullName: '',
      id: '', // ToDo
      lastname: '',
      nationality: 'DE',
      originCountry: '', // ToDo
      pickUpEntitled: true,
      portalLoginAllowed: false, // ToDo
      salutation: null,
      sex: 'MALE',
      singleParent: false,
      soleCustody: false,
      title: '',
    },
    migrantBackground: false,
    nativeLanguage: '',
    nutritionInfo: {porkMeatAllowed: false, allergic: false, allergies: null},
    parentalConsents: {
      applyPlasterSalveAllowed: false,
      beingPhotographedPrivatelyAllowed: false,
      beingPhotographedPubliclyAllowed: false,
      contactToAnimalsAllowed: false,
      dsgvoConfirmationObtained: false,
      goSwimmingAllowed: false,
      removeTickAllowed: false,
      ridingCarAllowed: false,
      applySuncreamAllowed: false,
    },
    possiblePreschooler: false,
    preschooler: false,
    promotionCommunityKey: '',
    promotionCommunityName: '',
    refNr: '',
    relatedPersons: [],
    shareInformationAllowed: false,
    sibling: false,
    siblings: [],
    siblingNamesString: '',
    siblingNames: [],
    urgency: 0,
  }
}

<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3><img width="100px" src="../assets/icons/children-group.png"/>&nbsp;&nbsp;&nbsp;Gruppen</h3>
        </div>
      </div>

      <div v-if="organization && organization.facilities && organization.facilities.length <= 0"
           class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Gruppen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell large-6">
          <div>
            <md-table v-model="searchedGroups" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                      @md-selected="onSelectGroup" style="height: 565px">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                  <div class="cell large-3 hide-for-medium-only hide-for-small-only">
                    <p class="md-title ai-number">{{searchedGroups.length}} Gruppe<span v-if="searchedGroups.length !== 1">n</span></p>
                  </div>
                  <div class="cell large-5 medium-7 small-12">
                    <md-field v-if="availableFacilities && availableFacilities.length > 0">
                      <label>Einrichtung</label>
                      <md-select v-model="selectedFacilityReferenceNumber"
                                 :disabled="sending || availableFacilities.length <= 1"
                                 @md-selected="onSelectFacility">
                        <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                   :key="facility.referenceNumber">
                          {{ facility.longName }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="cell large-4 medium-5 small-12">
                    <md-field md-clearable class="md-toolbar-section-end">
                      <md-input placeholder="Suche Gruppen-Name" v-model="searchGroup"
                                @input="searchOnGroupTable"/>
                    </md-field>
                  </div>
                  <div class="cell" style="padding-bottom: 15px;">
                    <div class="grid-x grid-padding-x">
                      <div class="cell large-3 medium-7 hide-for-small-only">
                        <div v-if="sending">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                      </div>
                      <div class="cell large-5 hide-for-medium-only hide-for-small-only">
                      </div>
                      <div v-if="canWrite && (isAdmin || isFacility || isFacilityAdmin || isManagement)" class="cell medium-3 large-4">
                        <md-button @click="onAddGroup" :disabled="sending" class="md-icon-button md-raised md-primary">
                          <md-icon>add</md-icon>
                          <md-tooltip>Neue Gruppe hinzufügen</md-tooltip>
                        </md-button>
                        <md-button @click="onEditGroup" :disabled="!selected" class="md-icon-button md-raised md-third">
                          <md-icon>edit</md-icon>
                          <md-tooltip>Gruppe bearbeiten</md-tooltip>
                        </md-button>
                        <md-button @click="deleteGroupDialog" :disabled="!selected || !isAdmin" class="md-icon-button md-raised md-accent">
                          <md-icon>delete</md-icon>
                          <md-tooltip>Gruppe löschen</md-tooltip>
                        </md-button>
                      </div>
                    </div>
                  </div>

                </div>
              </md-table-toolbar>

              <md-table-empty-state v-if="searchGroup"
                                    md-label="Keine Gruppe gefunden"
                                    :md-description="`Keine Gruppe mit dem Suchbegriff '${searchGroup}' gefunden. Versuchen Sie es mit einem anderen Begriff oder legen Sie neue Gruppe an.`">
              </md-table-empty-state>

              <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                            md-selectable="single">
                <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}
                </md-table-cell>
                <md-table-cell md-label="Statistik" md-tooltip="Relevant für Landesamt für Statistik" md-sort-by="relevantForLandesamtStatistik">
                  <md-icon v-if="item.relevantForLandesamtStatistik" style="color: green">done</md-icon>
                  <md-icon v-else style="color: lightgray">remove</md-icon>
                </md-table-cell>
                <md-table-cell md-label="Beschreibung" md-sort-by="description">{{ item.description }}
                </md-table-cell>
                <md-table-cell v-if="selectedFacilityReferenceNumber === '*'" md-label="Einrichtung"
                               md-sort-by="facilityReference">{{ availableFacilitiesNames[item.facilityReference] }}
                </md-table-cell>
                <md-table-cell md-label="Anzahl Kinder" md-sort-by="numberOfChildren">{{ item.numberOfChildren }}
                </md-table-cell>
              </md-table-row>

            </md-table>

          </div>
        </div>
        <div class="cell large-6">
          <md-card>
            <md-card-header>
              <p class="md-title"><span class="hide-for-medium-only hide-for-small-only">Gruppe: </span>
                <span class="group-title" v-if="selectedGroup">{{selectedGroup.name}}
                  <span
                      style="color: lightseagreen; font-size: 1.1rem">&nbsp;<i>({{selectedGroupFacilityName}})</i></span>
                </span>
                <span class="group-title" style="color: lightseagreen;" v-else><i>Bitte Gruppe wählen</i></span>
              </p>
            </md-card-header>

            <div id="groupChildrenTable">
              <md-card-content>
                <md-table v-model="searchedChildren" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                          @md-selected="onSelectChildren">
                  <md-table-toolbar>
                    <div class="md-toolbar-section-start grid-x grid-padding-x">
                      <div class="cell large-4 hide-for-medium-only hide-for-small-only">
                        <p class="md-title ai-number">{{searchedChildren.length}} Kinder</p>
                      </div>
                      <div class="cell large-8 medium-12 small-12">
                        <md-field md-clearable class="md-toolbar-section-end">
                          <md-input placeholder="Suche Nachname ..." v-model="searchChild"
                                    @input="searchOnChildTable"/>
                        </md-field>
                      </div>
                    </div>
                  </md-table-toolbar>

                  <md-table-empty-state v-if="searchChild"
                                        md-label="Kein Kind gefunden"
                                        :md-description="`Kein Kind mit dem Suchbegriff '${searchChild}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                  </md-table-empty-state>

                  <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                                md-selectable="multiple"
                                md-auto-select>
                    <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Vorname"
                                   md-sort-by="masterdata.firstname">
                      <ImageView :imageId="item.imageId" size="micro"></ImageView>
                      &nbsp;&nbsp;&nbsp;{{ item.masterdata.firstname }}
                    </md-table-cell>
                    <md-table-cell md-label="Nachname" md-sort-by="masterdata.lastname">{{ item.masterdata.lastname }}
                    </md-table-cell>
                    <md-table-cell md-label="Alter" md-sort-by="age">{{ item.age }}
                    </md-table-cell>
                    <md-table-cell md-label="Bereich" md-sort-by="divisionTypeLabel">{{ item.divisionTypeLabel }}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
            </div>

            <md-card-content>
              <div class="grid-x">

                <div class="cell small-6 text-left">
                  <md-button class="md-icon-button md-raised md-primary" @click="onAssignChildToGroup"
                             :disabled="!(selectedGroup != null) || !(canWrite && (isAdmin || isFacility || isFacilityAdmin || isManagement))">
                    <md-icon>add</md-icon>
                    <md-tooltip md-direction="right">
                      <b>Kinder</b> der Gruppe <b v-if="selectedGroup">{{selectedGroup.name}}</b> zuweisen
                    </md-tooltip>
                  </md-button>
                </div>
                <div class="cell small-6 text-right">
                  <md-button class="md-icon-button md-raised md-accent" @click="onRemoveChildren"
                             :disabled="!(selectedChildren.length > 0 && selectedGroup != null) || !(canWrite && (isAdmin || isFacility || isFacilityAdmin || isManagement))">
                    <md-icon>remove</md-icon>
                    <md-tooltip v-if="selectedChildren.length > 0 && selectedGroup" md-direction="left">
                      <b><span v-if="selectedChildren.length > 0">{{selectedChildren.length}}&nbsp;</span>
                        Kind<span v-if="selectedChildren.length > 1">er</span></b> aus der Gruppe <b>{{selectedGroup.name}}</b>
                      entfernen
                    </md-tooltip>
                  </md-button>
                </div>
              </div>
            </md-card-content>

          </md-card>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteGroupDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <img width="100px" src="../assets/icons/children-group.png"/>&nbsp;&nbsp;&nbsp;Gruppe der Einrichtung <span
              class="group-title">{{getFacilityName(this.selected.facilityReference)}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Gruppe <span class="group-title"><b>{{lastGroup}}</b></span> der Einrichtung <span
                class="group-title">{{getFacilityName(this.selected.facilityReference)}}</span> wirklich gelöscht
              werden?
            </md-content>
          </div>
          <br><br>
          <div class="cell text-center">
            <h5><b style="color: red;">Achtung!</b></h5>
            <span style="font-size: larger;">Bestehende Zuordnungen zu Kindern und Mitarbeitern werden dabei automatisch entfernt.</span>
          </div>
          <div class="cell">
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
          </div>
          <div class="cell text-center">
            <button @click="onDeleteGroup" class="button alert" :disabled="sending || !absolutelySure">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Gruppe jetzt löschen
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteGroupDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteGroupDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showRemoveChildFromGroupDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-minus"></i><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Kind<span
              v-if="deleteMultipleChildren">er</span> aus Gruppe
            <span class="group-title">{{selectedGroup.name}}</span> entfernen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll<span v-if="deleteMultipleChildren">en die Kinder </span><span v-else> das Kind </span>
              <span class="group-title"><b>{{selectedChildFullName}}</b></span> aus der Gruppe
              <span class="group-title">{{selectedGroup.name}}</span> wirklich entfernt werden?
            </md-content>
          </div>
          <div class="cell">
            <md-progress-bar v-if="sending && progress > 0" md-mode="determinate" :md-value="progress"/>
          </div>
          <div v-if="sending" class="cell text-center">
            <vue-simple-spinner></vue-simple-spinner>
            <br>
          </div>
          <div v-else class="cell text-center">
            <div @click="onRemoveChildrenFromGroup" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              {{selectedChildren.length}} Kind<span v-if="deleteMultipleChildren">er</span> jetzt entfernen
            </div>
          </div>
          <button class="close-button" type="button" @click="showRemoveChildFromGroupDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditGroupDialog :showDialog="showEditGroupDialog" :group="selectedGroup" :mode="dialogMode"
                       :availableFacilities="availableFacilities"
                       @updateGroupSuccess="updateGroupSuccess"
                       @closeEditGroupDialog="showEditGroupDialog = false"></EditGroupDialog>

      <SelectChildDialog ref="selectChildDialogRef" :showDialog="showSelectChildDialog" :group="selectedGroup"
                         :selectedFacility="selectedFacility" :children="children"
                         @closeSelectChildDialog="showSelectChildDialog = false"
                         @childSuccessfullyAssigned="onChildSuccessfullyAssigned"></SelectChildDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Gruppen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import GroupService from '../services/GroupService';
  import EditGroupDialog from '../components/dialogs/EditGroupDialog';
  import SelectChildDialog from '../components/dialogs/SelectChildDialog';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import Group from '../entities/Group';
  import ImageView from '../components/views/ImageView';
  import {validationMixin} from 'vuelidate';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchGroupByLastname = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  const searchChildByLastname = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'Groups',
    mixins: [validationMixin],

    components: {
      NoFacilityCard,
      NoModuleCard,
      EditGroupDialog,
      SelectChildDialog,
      Group,
      ImageView,
      'vue-simple-spinner': Spinner,
    },

    mounted() {
      this.restoreUserSettings();
      this.reloadGroups();

      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });

      HttpErrorHandler.maintainDarkMode(this);
    },

    updated() {
      this.resizeDialogElements();
    },

    data() {
      return {
        sending: false,
        groups: [],
        searchedGroups: [],
        children: [],
        searchedChildren: [],
        searchGroup: null,
        searchChild: null,
        selected: null,
        selectedChild: null,
        selectedChildren: [],
        showDeleteGroupDialog: false,
        showEditGroupDialog: false,
        showSelectChildDialog: false,
        showRemoveChildFromGroupDialog: false,
        lastGroup: '',
        selectedChildFullName: '',
        dialogMode: 'update',
        selectedGroup: null,
        selectedGroupFacilityName: '',
        editActive: false,
        availableFacilities: [],
        availableFacilitiesNames: {},
        allowedFacilities: [],
        selectedFacility: null,
        selectedFacilityReferenceNumber: null,
        responseCount: 0,
        deleteMultipleChildren: false,
        progress: 0,
        absolutelySure: false,
      }
    },

    methods: {

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === 'null') {
          this.selectedFacilityReferenceNumber = null;
        }
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacility = this.availableFacilities[0];
        }

        this.allowedFacilities = [];
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            this.allowedFacilities.push(facility);
            this.availableFacilitiesNames[facility.referenceNumber] = facility.name;
          }
        }

        jQuery('#groupChildrenTable .md-card-content .md-card.md-table').attr('style', 'height: 370px');
      },

      reloadGroups() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getAllGroups();
              this.buildAvailableFacilities();
            } else {
              if (!this.selectedFacilityReferenceNumber) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
              this.buildAvailableFacilities();
              this.onSelectFacility();
            }

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 675px');


            setTimeout(() => {
              if (this.selectedGroup) {
                jQuery('#' + this.selectedGroup.id).trigger('click');
              }
            }, 250);

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAllGroups() {
        this.sending = true;
        GroupService.getAllGroups()
          .then(response => {
            this.groups = response.data;
            this.searchOnGroupTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen');
            this.sending = false;
          })
      },

      getAllOrganizationGroups() {
        this.sending = true;
        GroupService.getAllOrganizationGroups()
          .then(response => {
            this.groups = response.data;
            this.searchOnGroupTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen für Träger ' + this.organization.name);
            this.sending = false;
          })
      },

      getAllFacilityGroups(facilityReferenceNo) {
        this.sending = true;
        GroupService.getAllFacilityGroups(facilityReferenceNo)
          .then(response => {
            this.groups = response.data;
            this.searchOnGroupTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen für Einrichtung mit Nummer ' + facilityReferenceNo);
            this.sending = false;
          })
      },

      getChildrenOfGroup(groupId) {
        this.sending = true;
        GroupService.getChildrenOfGroup(groupId)
          .then(response => {
            this.children = response.data;
            this.searchOnChildTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Kinder für Gruppe mit Id ' + groupId);
            this.sending = false;
          })
      },

      updateGroup() {
        this.sending = true;
        GroupService.updateGroup(this.selectedGroup)
          .then(response => {
            this.form = response.data;
            this.sending = false;
            this.updateGroupSuccess(response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Gruppe ' + this.selectedGroup.name);
            this.sending = false;
          })
      },

      updateGroupSuccess(updatedGroup) {
        this.showEditGroupDialog = false;
        this.lastGroup = this.selectedGroup.name;

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Änderungen für Gruppe&nbsp;<b>' + this.lastGroup + '</b>&nbsp;wurden erfolgreich gespeichert!');
        }

        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Die Gruppe&nbsp;<b>' + this.lastGroup + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
        }

        this.selected = null;
        this.selectedGroup = updatedGroup;
        this.reloadGroups();
      },

      onAddGroup() {
        this.dialogMode = 'add';
        this.selectedGroup = JSON.parse(JSON.stringify(Group.group));
        if (this.allowedFacilities.length === 1) {
          this.selectedGroup.facilityReferenceNumber = this.allowedFacilities[0].referenceNumber;
        } else {
          this.selectedGroup.facilityReferenceNumber = this.selectedFacilityReferenceNumber;
        }

        this.showEditGroupDialog = true;
      },

      onEditGroup() {
        if (this.selected) {
          this.dialogMode = 'update';
          this.selectedGroup = JSON.parse(JSON.stringify(this.selected));
          this.selectedGroup.facilityReferenceNumber = this.selectedFacilityReferenceNumber;
          this.showEditGroupDialog = true;
        }
      },

      deleteGroupDialog() {
        if (this.selected) {
          this.lastGroup = this.selectedGroup.name;
          this.absolutelySure = false,
          this.showDeleteGroupDialog = true;
        }
      },

      onDeleteGroup() {
        if (this.selected) {
          this.sending = true;
          GroupService.deleteGroup(this.selected.id)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Gruppe &nbsp;<b>' + this.lastGroup + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.searchGroup = null;
              this.selected = null;
              this.selectedGroup = null;
              this.showDeleteGroupDialog = false;
              this.reloadGroups();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Gruppe ' + this.selectedGroup.name);
              this.sending = false;
            })
        }
      },

      searchOnGroupTable() {
        this.searchedGroups = searchGroupByLastname(this.groups, this.searchGroup);
        this.resizeDialogElements();
      },

      searchOnChildTable() {
        for (let child of this.children) {
          child.divisionTypeLabel = this.labels.divisiontype[child.divisiontype];
        }
        this.searchedChildren = searchChildByLastname(this.children, this.searchChild);
        this.resizeDialogElements();
      },

      resizeDialogElements() {
        if (!this.showSelectChildDialog) {
          // Table header
          setTimeout(() => jQuery('.md-table-fixed-header-container').last().find('div.md-table-head-label:contains(Vorname)').attr('style', 'padding: 0px 20px 0px 24px;'), 10);
          setTimeout(() => jQuery('.md-table-fixed-header-container').last().find('div.md-table-head-label:contains(Name)').attr('style', 'padding: 0px 20px 0px 24px;'), 15);

          // Table rows
          setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 10px 6px 10px;'), 30);
          setTimeout(() => jQuery('.md-table-cell').attr('style', 'padding: 6px 10px 6px 10px;'), 40);
          setTimeout(() => jQuery('.md-content.md-table-content.md-scrollbar').first().width(jQuery('.md-card.md-table').width()), 50);
          setTimeout(() => jQuery('.md-content.md-table-content.md-scrollbar').first().next().width(jQuery('.md-card.md-table').width()), 60);
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      onSelectGroup(item) {
        if (item) {
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedGroup = JSON.parse(JSON.stringify(this.selected));
          this.selectedGroup.facilityReferenceNumber = this.selectedFacilityReferenceNumber;
          for (let facility of this.availableFacilities) {
            if (this.selectedGroup.facilityReference === facility.referenceNumber) {
              this.selectedGroupFacilityName = facility.name;
            }
          }

          this.getChildrenOfGroup(this.selectedGroup.id);
        } else {
          if (this.selectedGroup) {
            jQuery('#' + this.selectedGroup.id).trigger('click');
          }
          this.selected = null;
          this.selectedGroup = null;
          this.children = [];
          this.searchedChildren = [];
        }
      },

      onSelectChildren(items) {
        if (items) {
          this.selectedChildren = JSON.parse(JSON.stringify(items));
        } else {
          this.selectedChildren = [];
        }
      },

      onSelectFacility() {
        this.searchGroup = '';
        this.searchChild = '';
        if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          this.selectedFacility = null;
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacility = facility;
            }
          }

          if (this.selectedFacilityReferenceNumber === '*') {
            this.getAllOrganizationGroups();
          } else {
            if (this.selectedFacilityReferenceNumber) {
              this.getAllFacilityGroups(this.selectedFacilityReferenceNumber);
            }
          }
        }
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      getFacilityName(referenceNumber) {
        if (referenceNumber && referenceNumber.length > 0 && this.organization.facilities) {
          for (let facility of this.organization.facilities) {
            if (facility.referenceNumber === referenceNumber) {
              return facility.name;
            }
          }
        }
        return '';
      },

      onAssignChildToGroup() {
        this.$refs.selectChildDialogRef.getAllOrganizationChildren();
        this.showSelectChildDialog = true;
      },

      updateNumberOfChildrenForGroup(groupId, numberOfChildren) {
        for (let i = 0; i < this.searchedGroups.length; i++) {
          if (this.searchedGroups[i].id === groupId) {
            this.searchedGroups[i].numberOfChildren = numberOfChildren;
            this.groups[i].numberOfChildren = numberOfChildren;
          }
        }
      },

      onRemoveChildrenFromGroup() {
        this.responseCount = 0;
        this.progress = 0;
        for (let child of this.selectedChildren) {
          this.selectedChild = child;
          this.onRemoveChildFromGroup(this.selectedChildren.length);
        }
      },

      onRemoveChildFromGroup(numRequests) {
        if (this.selectedGroup && this.selectedChild) {
          this.sending = true;
          GroupService.removeChildFromGroup(this.selectedChild.id, this.selectedGroup.id)
            .then(response => {
              this.responseCount++;
              this.progress = Number(this.responseCount / numRequests) * 100;
              if (this.responseCount >= numRequests) {
                this.sending = false;
                if (this.selectedChildren.length === 1) {
                  this.$store.commit('successMsg', 'Das Kind <b>&nbsp;' + this.selectedChildFullName +
                    '</b>&nbsp;wurde erfolgreich aus der Gruppe &nbsp;<b>' + this.selectedGroup.name + '</b>&nbsp;entfernt.');
                } else {
                  this.$store.commit('successMsg', '<b>' + this.selectedChildren.length +
                    '</b>&nbsp;Kinder wurden erfolgreich aus der Gruppe &nbsp;<b>' + this.selectedGroup.name + '</b>&nbsp;entfernt.');
                }
                this.selectedChild = null;
                this.showRemoveChildFromGroupDialog = false;
                this.updateNumberOfChildrenForGroup(this.selectedGroup.id, response.data.numberOfChildren);
                this.getChildrenOfGroup(this.selectedGroup.id);
              }
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Entfernen von Kind ' + this.selectedChildFullName);
              this.sending = false;
            })
        }
      },

      onRemoveChildren() {
        if (this.selectedChildren.length > 0) {
          this.selectedChildFullName = '';
          let i = 0;
          for (let child of this.selectedChildren) {
            this.selectedChildFullName += child.masterdata.firstname + ' ' + child.masterdata.lastname;
            i++;
            if (i < this.selectedChildren.length) {
              this.selectedChildFullName += ', ';
            }
          }
          this.deleteMultipleChildren = this.selectedChildren.length > 1;
          this.showRemoveChildFromGroupDialog = true;
        }
      },

      onChildSuccessfullyAssigned(numberOfChildren) {
        this.showSelectChildDialog = false;
        this.updateNumberOfChildrenForGroup(this.selectedGroup.id, numberOfChildren);
        this.getChildrenOfGroup(this.selectedGroup.id);
      },
    },

    computed: {
      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacility() {
        return this.$store.getters.facility;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      organization() {
        return this.$store.getters.organization;
      },

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },
    }
  }
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .md-tab {
    padding-left: 8px;
    padding-right: 8px;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }
</style>
